import React, { useState, useEffect } from 'react';
import {
	Table,
	Input,
	DatePicker,
	TreeSelect,
	// Divider,
	// Space,
	// Button,
} from 'antd';
import dayjs from 'dayjs';
import { AddSvg, DeleteActionSvg } from 'utils/svgs';
import styles from './index.module.scss';
// import { PlusOutlined } from '@ant-design/icons';
import { postApi } from 'redux/apis';
import { toastText } from 'utils/utils';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { historyOfMapping } from 'constants/Data';

interface DataItem {
	key: string;
	date: any;
	employee: any;
	class: any;
	customerName: any;
	className: any;
	customer: any;
	hours: string;
}

interface HeaderMapping {
	[key: string]: any;
	activityDate: string[];
	employee: string[];
	customer: string[];
	class: string[];
	hours: string[];
}
interface EditableTableProps {
	data: DataItem[];
	selectHeadersValues: HeaderMapping;
	handleBack: () => void;
	closeMappingModal: () => void;
	classList: any[];
	employees: any[];
	customerOptions: any[];
	fileClassValues: {
		key: string;
		selectedValue: string;
		qboClassName: string;
	}[];
	fileEmployeeValues: { key: string; selectedValue: string }[];
	fileCustomerValues: {
		key: string;
		selectedValue: string;
		qboCustomerName: string;
	}[];
	payPeriodsData: any;
	handleImportedActivity: () => void;
}

const EditableTable: React.FC<EditableTableProps> = ({
	data,
	selectHeadersValues,
	handleBack,
	closeMappingModal,
	classList,
	employees,
	customerOptions,
	fileClassValues,
	fileEmployeeValues,
	fileCustomerValues,
	handleImportedActivity,
	// payPeriodsData,
}) => {
	// const [selectedPayPeriod, setSelectedPayPeriod] = useState<string>('');
	const [dataSource, setDataSource] = useState<DataItem[]>([]);
	// const [selectedPayPeriodError, setSelectedPayPeriodError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	/* 	const [showRefresh, setShowRefresh] = useState(false);
	 */
	useEffect(() => {
		const mapData = () => {
			// Create a mapping from raw data keys to the desired keys
			const keyMapping: Record<string, string[]> = Object.keys(
				selectHeadersValues
			).reduce((acc, key) => {
				// Store the array of keys directly
				acc[key] = selectHeadersValues[key];
				return acc;
			}, {} as Record<string, string[]>);
			// Transform the raw data into the desired format

			const customerLookup = fileCustomerValues.reduce(
				(acc: any, item: any) => {
					acc[item.key] = {
						key: item.selectedValue,
						value: item.qboCustomerName,
					};
					return acc;
				},
				{}
			);

			const classLookup = fileClassValues.reduce((acc: any, item: any) => {
				acc[item.key] = {
					key: item.selectedValue,
					value: item.qboClassName,
				};
				return acc;
			}, {});

			const employeeLookup = fileEmployeeValues.reduce(
				(acc: any, item: any) => {
					acc[item.key] = {
						key: item.selectedValue,
						value: item.qboEmployeeName,
					};

					return acc;
				},
				{}
			);

			const getMappedValueForData = (
				keyArray: any,
				record: any,
				lookupArray: any
			) => {
				if (!keyArray || keyArray.length === 0) {
					return { key: '', value: '' };
				} else {
					const concatenatedString = keyArray
						.map((key: any) => record[key])
						.join('_');
					return concatenatedString ? lookupArray[concatenatedString] : '';
				}
			};

			dayjs.extend(customParseFormat);
			dayjs.extend(isBetween);

			const excelDateToJSDate = (serial: number): string => {
				const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel's base date (12/30/1899)

				// Convert serial number to a JS date, adding the days (in ms) to the base date
				const jsDate = new Date(excelEpoch.getTime() + serial * 86400000); // 86400000 ms = 1 day
				jsDate.setUTCHours(0, 0, 0, 0); // Reset time to 00:00:00 to ignore the time part

				// Correct for Excel's leap year bug (serial > 60)
				if (serial > 60) {
					jsDate.setUTCDate(jsDate.getUTCDate() + 1);
				}

				// Adjust for UTC+6:00 timezone
				jsDate.setUTCHours(jsDate.getUTCHours() - 6);

				// Return the date as YYYY-MM-DD without considering local time zone or time shifts
				const year = jsDate.getUTCFullYear();
				const month = (jsDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
				const day = jsDate.getUTCDate().toString().padStart(2, '0'); // Ensure two digits for day

				// Construct and return the formatted date string in YYYY-MM-DD format
				return `${year}-${month}-${day}`;
			};

			const formatDate = (date: string | number | Date): string | null => {
				let parsedDate: dayjs.Dayjs | null = null;

				// Handle Excel serial number
				if (typeof date === 'number') {
					parsedDate = dayjs(excelDateToJSDate(date));
				}

				// Handle Date object
				else if (date instanceof Date) {
					parsedDate = dayjs(date);
				}

				// Handle string formats
				else if (typeof date === 'string') {
					const allowedFormats = [
						'MM-DD-YYYY',
						'MM/DD/YYYY',
						'MM-DD-YY',
						'MM/DD/YY',
						'M-D-YYYY',
						'M/D/YYYY',
						'M-D-YY',
						'M/D/YY',
						'MM-D-YYYY',
						'MM/D/YYYY',
						'M-DD-YYYY',
						'M/DD/YYYY',
						'MM-D-YY',
						'MM/D/YY',
						'M-DD-YY',
						'M/DD/YY',
						// Formats with time
						'MM/DD/YYYY h:mm:ss A',
						'M/D/YYYY h:mm:ss A',
						'MM-DD-YYYY h:mm:ss A',
						'M-D-YYYY h:mm:ss A',
						'MM/DD/YYYY HH:mm:ss',
						'M/D/YYYY HH:mm:ss',
						'MM-DD-YYYY HH:mm:ss',
						'M-D-YYYY HH:mm:ss',
						'YYYY-MM-DDTHH:mm:ssZ',
						'YYYY-MM-DDTHH:mm:ss.SSSZ', // ISO 8601 formats
						'YYYY-MM-DD HH:mm:ss',
						'YYYY-MM-DD HH:mm:ss.SSS',
					];

					parsedDate = dayjs(date, allowedFormats, true);

					// Check for single-month date (e.g., "3-2-2024") and exclude it
					if (
						parsedDate.isValid() &&
						(date.match(/^\d{1,2}-\d{1,2}-\d{4}$/) ||
							date.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/))
					) {
						const formattedDate = parsedDate.format('MM/DD/YYYY');
						const isSingleMonthDate =
							formattedDate.startsWith('0') && formattedDate[1] === '/';
						if (isSingleMonthDate) {
							return null;
						}
					}
				}

				return parsedDate && parsedDate.isValid()
					? parsedDate.format('MM/DD/YYYY')
					: null;
			};

			const transformedData = data
				.filter((item: any) => {
					// Check for null values in relevant keys
					const hasValidEmployeeData = keyMapping.employee
						.map((key) => item[key])
						.every((value) => value !== null && value !== undefined);

					const hasValidHoursData = Array.isArray(keyMapping.hours)
						? keyMapping.activityDate
								.map((key) => item[key])
								.every(
									(value) =>
										value !== null && value !== undefined && value !== ''
								)
						: item[keyMapping.hours] !== null &&
						  item[keyMapping.hours] !== undefined &&
						  item[keyMapping.hours] !== '';

					const hasValidClassData = keyMapping.class
						.map((key) => item[key])
						.every((value) => value !== null && value !== undefined);

					const hasValidCustomerData = keyMapping.customer
						.map((key) => item[key])
						.every((value) => value !== null && value !== undefined);

					const hasValidDateData = Array.isArray(keyMapping.activityDate)
						? keyMapping.activityDate
								.map((key) => item[key])
								.every(
									(value) =>
										value !== null && value !== undefined && value !== ''
								)
						: item[keyMapping.activityDate] !== null &&
						  item[keyMapping.activityDate] !== undefined &&
						  item[keyMapping.activityDate] !== '';

					return (
						hasValidEmployeeData &&
						hasValidHoursData &&
						hasValidClassData &&
						hasValidCustomerData &&
						hasValidDateData
					);
				})
				.map((item: any, index: number) => ({
					key: String(index),
					date: formatDate(item[keyMapping.activityDate.toString()]),
					employee: getMappedValueForData(
						keyMapping.employee,
						item,
						employeeLookup
					).key,

					class: getMappedValueForData(keyMapping.class, item, classLookup).key,
					className: getMappedValueForData(keyMapping.class, item, classLookup)
						.value,
					customer: getMappedValueForData(
						keyMapping.customer,
						item,
						customerLookup
					).key,
					customerName: getMappedValueForData(
						keyMapping.customer,
						item,
						customerLookup
					).value,
					hours: dayjs()
						.hour(
							Math.floor(parseFloat(item[keyMapping.hours.toString()] || 0))
						)
						.minute(
							Math.round(
								(parseFloat(item[keyMapping.hours.toString()]) % 1) * 60
							)
						)
						.format('HH:mm'),
				}));
			setDataSource(transformedData);
			setIsLoading(false);
		};
		mapData();
	}, [
		data,
		selectHeadersValues,
		fileClassValues,
		fileEmployeeValues,
		fileCustomerValues,
	]);
	const handleEdit = (index: number, field: keyof DataItem, value: any) => {
		const newData = dataSource.map((item, i) =>
			i === index ? { ...item, [field]: value } : item
		);
		setDataSource(newData);
	};

	const handleDelete = (index: number) => {
		setDataSource(dataSource.filter((_, i) => i !== index));
	};
	const handleAdd = () => {
		const newKey = `${dataSource.length}`;
		setDataSource([
			{
				key: newKey,
				date: '',
				employee: '',
				class: '',
				className: '',
				customer: '',
				customerName: '',
				hours: '',
			},
			...dataSource,
		]);
	};

	// const handlePayPeriodChange = async (value: string) => {
	// 	setSelectedPayPeriodError(false);
	// 	setSelectedPayPeriod(value);
	// };

	const handleImport = async () => {
		try {
			setIsLoading(true);
			// Call the post API for createOrUpdateTimelogMappingHistory
			try {
				const mappingData = {
					headerMapping: selectHeadersValues, // Assuming selectHeadersValues holds the headerMapping
					classMapping: fileClassValues, // Class mapping data from state
					employeeMapping: fileEmployeeValues, // Employee mapping data from state
					customerMapping: fileCustomerValues, // Customer mapping data from state
					companyId: localStorage.getItem('companyId'), // Company ID
					type: historyOfMapping.TimeActivity,
				};

				// Post API request to create or update timelog mapping history
				const historyResponse = await postApi(
					'/time-activities/create-update-timelog-mapping-history',
					mappingData
				);

				console.log(
					'Timelog mapping history created/updated successfully:',
					historyResponse
				);
				// toastText('Timelog mapping history updated successfully', 'success');
			} catch (error) {
				console.error(
					'Error creating/updating timelog mapping history:',
					error
				);
			}

			const payload = {
				companyId: localStorage.getItem('companyId'),
				activities: dataSource.map((activity: any) => ({
					employeeId: activity.employee,
					activityDate: activity?.date
						? dayjs(activity.date).toISOString()
						: '',
					hours: splitTime(activity.hours).hours,
					minute: splitTime(activity.hours).minutes,
					classId: activity.class,
					className: activity.className,
					customerName: activity.customerName,
					customerId: activity.customer,
					SplitTimeActivities: [],
					isCustomerSelected: !activity.class,
					isClassSelected: !activity.customer,
				})),
			};

			// Prepare the data payload for the API call
			// const payload = {
			// 	companyId: localStorage.getItem('companyId'),
			// 	payPeriodId: selectedPayPeriod,
			// 	activities: [
			// 		// Replace this with the actual activity data structure based on your application
			// 		{
			// 			hours: '11',
			// 			minute: '00',
			// 			classId: '522561',
			// 			className: null,
			// 			customerId: null,
			// 			customerName: 'Hieu Doann (1373)',
			// 			activityDate: '2023-07-14T18:30:00.000Z',
			// 			companyId: localStorage.getItem('companyId'),
			// 			payPeriodId: value,
			// 			employeeId: '28e23b4b-f127-452a-b78e-3841b51850f5',
			// 			SplitTimeActivities: [],
			// 			isCustomerSelected: false,
			// 			isClassSelected: false,
			// 		},
			// 	],
			// };

			// Make the API call
			// const response = await fetch(
			// 	'http://localhost:8080/time-activities/bulk-create-time-activities',
			// 	{
			// 		method: 'POST',
			// 		headers: {
			// 			'Content-Type': 'application/json',
			// 			Authorization: 'Bearer your-auth-token-here', // Replace with actual token if needed
			// 		},
			// 		body: JSON.stringify(payload),
			// 	}
			// );

			await postApi('/time-activities/bulk-create-time-activities', {
				...payload,
				companyId: localStorage.getItem('companyId'),
			});
			setIsLoading(false);
			handleImportedActivity();
			toastText('Time activities imported successfully.', 'success');
			closeMappingModal();
		} catch (error: any) {
			console.log(error);
			setIsLoading(false);
			if (error.response.data.responseStatus === 400) {
				console.error('Validation errors:', error.response.data);
				toastText(error.response.data.message, 'error');
			}
		}

		// Implement import logic here
	};

	/* const handleRefresh = async () => {
		try {
			const response = await getApi(
				'/time-activities/time-activities-import-status',
				{
					companyId: localStorage.getItem('companyId'),
				}
			);

			if (response.data.data) {
				// Handle the response
				if (response.data.data.status === 1) {
					setShowRefresh(false);
					setIsLoading(false);
					toastText(response.data.message, 'success');
					handleImportedActivity();
					// Handle success response
					// if (response.data.data.createdActivities.errors.length > 0) {
					// 	console.warn(
					// 		'Some activities failed validation:',
					// 		response.data.createdActivities.errors
					// 	);
					// 	toastText(response.data.message, 'error');
					// }
					closeMappingModal();
				}
			}
		} catch (error) {
			console.log('Error in time activity:', error);
		}
	}; */

	const columns = [
		{
			title: 'Activity Date',
			dataIndex: 'date',
			render: (text: string, record: DataItem, index: number) => (
				<DatePicker
					value={text ? dayjs(text) : null}
					onChange={(date, dateString) => handleEdit(index, 'date', dateString)}
					format="MM-DD-YYYY"
					style={{ width: '100%' }}
				/>
			),
		},
		{
			title: 'Employee',
			dataIndex: 'employee',
			render: (text: string, record: DataItem, index: number) => (
				<TreeSelect
					showSearch
					className={styles['search-filter__filter']}
					style={{ width: '100%' }}
					onChange={(value) => handleEdit(index, 'employee', value)}
					value={
						fileEmployeeValues.find(
							(item) => item.selectedValue == record.employee
						)?.selectedValue || ''
					} // Value should be the selected value
					placeholder="Select Employee"
					allowClear={false}
					treeDefaultExpandAll
					size="large"
					treeData={employees.map((employee) => ({
						value: employee.id,
						title: employee.fullName,
						children: [],
					}))}
					filterTreeNode={(inputValue, treeNode) =>
						treeNode.props.title
							.toLowerCase()
							.includes(inputValue.toLowerCase())
					}
					// dropdownRender={(menu) => (
					// 	<>
					// 		{menu}
					// 		<Divider style={{ margin: '8px 0' }} />
					// 		<Space style={{ padding: '0 8px 4px' }}>
					// 			<Button
					// 				type="text"
					// 				icon={<PlusOutlined />}
					// 			>
					// 				Add new Employee
					// 			</Button>
					// 		</Space>
					// 	</>
					// )}
				/>
			),
		},
		{
			title: 'Class',
			dataIndex: 'class',
			render: (text: string, record: DataItem, index: number) => (
				<TreeSelect
					showSearch
					className={styles['search-filter__filter']}
					style={{ width: '100%' }}
					onChange={(value) => handleEdit(index, 'class', value)}
					value={
						fileClassValues.find((item) => item.selectedValue == record.class)
							?.selectedValue || record.class
					} // Value should be the selected value
					placeholder="Select Class"
					allowClear={false}
					treeDefaultExpandAll
					size="large"
					treeData={classList} // Ensure you have the `classOptions` data
					filterTreeNode={(inputValue, treeNode) =>
						treeNode.props.title
							.toLowerCase()
							.includes(inputValue.toLowerCase())
					}
					// dropdownRender={(menu) => (
					// 	<>
					// 		{menu}
					// 		<Divider style={{ margin: '8px 0' }} />
					// 		<Space style={{ padding: '0 8px 4px' }}>
					// 			<Button
					// 				type="text"
					// 				icon={<PlusOutlined />}
					// 			>
					// 				Add new Class
					// 			</Button>
					// 		</Space>
					// 	</>
					// )}
				/>
			),
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			render: (text: string, record: DataItem, index: number) => (
				<TreeSelect
					showSearch
					className={styles['search-filter__filter']}
					style={{ width: '100%' }}
					onChange={(value) => handleEdit(index, 'customer', value)}
					value={
						fileCustomerValues.find(
							(item) => item.selectedValue == record.customer
						)?.selectedValue || record.customer
					} // Value should be the selected value
					placeholder="Select Customer"
					allowClear={false}
					treeDefaultExpandAll
					size="large"
					treeData={customerOptions} // Ensure you have the `customerOptions` data
					filterTreeNode={(inputValue, treeNode) =>
						treeNode.props.title
							.toLowerCase()
							.includes(inputValue.toLowerCase())
					}
					// dropdownRender={(menu) => (
					// 	<>
					// 		{menu}
					// 		<Divider style={{ margin: '8px 0' }} />
					// 		<Space style={{ padding: '0 8px 4px' }}>
					// 			<Button
					// 				type="text"
					// 				icon={<PlusOutlined />}
					// 			>
					// 				Add new Customer
					// 			</Button>
					// 		</Space>
					// 	</>
					// )}
				/>
			),
		},
		{
			title: 'Hours',
			dataIndex: 'hours',
			render: (text: string, record: DataItem, index: number) => (
				<Input
					value={text}
					onChange={(e) => handleEdit(index, 'hours', e.target.value)}
				/>
			),
		},
		{
			title: <div style={{ textAlign: 'center' }}>Action</div>,
			render: (_: any, record: DataItem, index: number) => (
				<div
					className={styles['action-svg']}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<span
						style={{ cursor: 'pointer' }}
						onClick={() => handleDelete(index)}
					>
						<DeleteActionSvg />
					</span>
					<p className="delete-text">Delete</p>
				</div>
			),
		},
	];

	return (
		<div>
			<div className={styles.container}>
				{/* <Form.Item
					validateStatus={selectedPayPeriodError ? 'error' : ''}
					help={selectedPayPeriodError ? 'Please select a pay period' : ''}
				>
					<Select
						id="pay-period-select"
						style={{
							width: 380,
							height: '45px',
							marginTop: '2px',
							borderColor: selectedPayPeriodError ? 'red' : '',
						}}
						placeholder="Select Pay Period"
						onChange={handlePayPeriodChange}
					>
						{payPeriodsData.map((item: any) => (
							<Select.Option
								key={item.id}
								value={item.id}
							>
								{`Start Date: ${dayjs(item.startDate).format(
									'MM-DD-YYYY'
								)}, End Date: ${dayjs(item.endDate).format('MM-DD-YYYY')}`}
							</Select.Option>
						))}
					</Select>
				</Form.Item> */}
				<button
					className={`btn-black`}
					style={{ height: '45px' }}
					onClick={handleAdd}
				>
					<AddSvg />
					<p>Add Time log</p>
				</button>
			</div>
			<div className={styles['table-container']}>
				<Table
					className={styles['dynamic-table']}
					dataSource={dataSource}
					columns={columns}
					rowKey="key"
					// style={{ textAlign: 'center' }}
					loading={isLoading}
					pagination={false}
					scroll={{ y: 'calc(100vh - 500px)' }}
				/>
			</div>

			<div
				style={{
					marginTop: '20px',
					display: 'flex',
					justifyContent: 'start',
					alignItems: 'center',
				}}
			>
				<button
					key="cancel"
					onClick={handleBack}
					className={`${styles['confirm-delete-model__button--cancel']} ${styles['confirm-delete-model__button--btn']}`}
					style={{ marginRight: '8px' }} // Add space between buttons
					disabled={isLoading}
				>
					Back
				</button>

				<button
					className="btn-black"
					onClick={handleImport}
					disabled={isLoading}
				>
					Import
				</button>
				{/* {showRefresh && (
					// <button
					// 	className="btn-black"
					// 	style={{ marginRight: '8px' }}
					// 	onClick={handleRefresh}
					// >
					// 	Refresh
					// </button>
					<Tooltip
						title="Refresh to get uploaded time activity"
						getPopupContainer={(triggerNode) =>
							triggerNode.parentElement || document.body
						}
					>
						<div
							// style={{ display: 'inline-block', zIndex: 9999 }}
							style={{
								marginLeft: '8px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								zIndex: 99999,
							}}
							onClick={handleRefresh}
						>
							<SyncNowSvg />
						</div>
					</Tooltip>
				)} */}

				{/* <Typography.Text
					type="secondary"
					style={{
						alignItems: 'center',
						color: '#E74032',
						marginLeft: 'auto',
						marginTop: 'auto',
					}}
				>
					<InfoCircleOutlined
						style={{ color: '#E74032', marginRight: '2px' }}
					/>
					Before time activity import match selected pay periods with file
					Activity Date.
				</Typography.Text> */}
			</div>
		</div>
	);
};

export default EditableTable;

function splitTime(time: string) {
	const [hours, minutes] = time.split(':').map(String);
	return { hours, minutes };
}
