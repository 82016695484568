// useWebSocket.ts (React hook for WebSocket)

import { useState, useEffect } from 'react';

const useWebSocket = (url: string, userId: string) => {
	const [message, setMessage] = useState<any>([]);
	const [ws, setWs] = useState<WebSocket | null>(null);

	useEffect(() => {
		let socket: any = undefined;
		const setUpWebsocket = () => {
			socket = new WebSocket(url);
			socket.onopen = () => {
				console.log('Connected to WebSocket server');
				socket?.send(
					JSON.stringify({
						type: 'register',
						userId: userId,
					})
				);
			};

			socket.onmessage = (event: any) => {
				console.log('data message', event);
				const data = JSON.parse(event.data);
				setMessage((prevMessages: any) => [...prevMessages, data]); // Add the new message to the list
			};

			socket.onclose = (event: any) => {
				console.log('Disconnected from WebSocket server', event);
				setTimeout(() => {
					console.log('Reconnect from WebSocket server');
					setUpWebsocket();
				}, 5000);
			};

			setWs(socket);
		};

		setUpWebsocket();

		return () => {
			socket.close();
		};
	}, [url]);

	return { message, ws };
};

export default useWebSocket;
