import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Space, Checkbox } from 'antd';
import PayPeriodFilter from 'components/Global/PayPeriodFilter';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utils/utils';
import styles from './index.module.scss';
import moment from 'moment';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import './index.scss';
import { useSearchParams } from 'react-router-dom';

const SearchAndFilter = ({
	searchValue,
	onChangeSearchValue,
	onChangePayPeriodId,
	payPeriodId,
	closingDate,
	handleInactive,
	includeInactive,
	isConfigurationPermission,
	showEmployeeWithAllocationConfig,
	onChangeShowEmployeeWithAllocationConfig,
	handleShowPayPeriodCreationRefresh,
}: // onSyncEmployee,
ISearchAndFilter) => {
	const [payPeriodData, setPayPeriodData] = useState<any>(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [queryParams, setQueryParams] = useSearchParams();

	const [isNewPayPeriod, setIsNewPayPeriod] = useState<string | null>(
		queryParams.get('isNewPayPeriod')
	);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	const isAddPayPeriodPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Pay Period',
		permission: ['add'],
	});

	const handleChangePayPeriodId = (id: any) => {
		setIsNewPayPeriod('false');
		onChangePayPeriodId && onChangePayPeriodId(id);
	};

	const handleChangeSearchValue = (value: any) => {
		onChangeSearchValue && onChangeSearchValue(value);
	};

	const handleInactiveEmployee = (e: CheckboxChangeEvent) => {
		handleInactive(e.target.checked);
	};

	const handleConfigOpen = () => {
		const _query: any = {
			openDrawer: 'true',
		};

		if (queryParams.get('payPeriodId')) {
			_query.payPeriodId = payPeriodId;
		}

		if (queryParams.get('isNewPayPeriod') === 'true') {
			_query.isNewPayPeriod = 'true';
		}

		setQueryParams(_query);
		localStorage.setItem('settings', 'Configurations');
	};

	useEffect(() => {
		if (payPeriods && payPeriods.length && payPeriodId) {
			const data = payPeriods.find((e: any) => e.id === payPeriodId);
			setPayPeriodData(data);
		} else {
			setPayPeriodData(null);
		}
	}, [payPeriodId, payPeriods]);

	return (
		<Row>
			<Col className={styles['search-filter-main']}>
				<div className={styles['search-filter-main-picker']}>
					<div className={styles['search-filter-main-picker-left']}>
						<Space className={styles['search-filter']}>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								onChange={(e) => {
									handleChangeSearchValue(e.target.value);
								}}
								size="large"
								value={searchValue}
								defaultValue={searchValue}
							/>
						</Space>
						<PayPeriodFilter
							payPeriodId={payPeriodId}
							onChangePayPeriodId={(value) => handleChangePayPeriodId(value)}
							createNewPayPeriod={isAddPayPeriodPermission}
							defaultModalOpen={
								isNewPayPeriod == 'true' ? Boolean(isNewPayPeriod) : false
							}
							handleShowPayPeriodCreationRefresh={
								handleShowPayPeriodCreationRefresh
							}
						/>
						{payPeriodData?.isJournalPublished &&
						closingDate &&
						dayjs(payPeriodData?.endDate) > dayjs(closingDate).endOf('day') ? (
							<p
								style={{
									color: 'white',
									padding: '5px 10px',
									borderRadius: '5px',
									background: '#009049',
									fontSize: '18px',
								}}
							>
								Published
							</p>
						) : closingDate &&
						  dayjs(payPeriodData?.endDate) <
								dayjs(closingDate).endOf('day') ? (
							<p
								style={{
									color: 'white',
									padding: '5px 10px',
									borderRadius: '5px',
									background: 'red',
									fontSize: '18px',
								}}
							>
								Closed
							</p>
						) : null}

						{payPeriodId && isConfigurationPermission ? (
							<p
								className="config-link"
								onClick={() => handleConfigOpen()}
							>
								Configurations
							</p>
						) : null}
					</div>
					<div
						className={`${styles['search-filter-main-picker-right']} search-filter-main-picker-right`}
					>
						{closingDate ? (
							<p style={{ color: '#818181' }}>
								QuickBooks Closing Date:{' '}
								{moment(closingDate).format('MM/DD/YYYY')}
							</p>
						) : null}
						<Checkbox
							onChange={handleInactiveEmployee}
							checked={includeInactive}
						>
							Include Inactive
						</Checkbox>
						<Checkbox
							onChange={(e) =>
								onChangeShowEmployeeWithAllocationConfig(e.target.checked)
							}
							checked={showEmployeeWithAllocationConfig}
						>
							Fixed %
						</Checkbox>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default SearchAndFilter;

interface ISearchAndFilter {
	searchValue: any;
	onChangeSearchValue: any;
	// dateRangeDateHandler: any;
	// payPeriodDateRange: any;
	// onSavePayPeriod: any;
	onChangePayPeriodId: any;
	payPeriodId: any;
	onSyncEmployee: any;
	currentDatePayPeriodId: string;
	closingDate: string | null;
	handleInactive: (e: boolean) => void;
	includeInactive: boolean;
	isConfigurationPermission: boolean;
	showEmployeeWithAllocationConfig: boolean;
	onChangeShowEmployeeWithAllocationConfig: (e: boolean) => void;
	handleShowPayPeriodCreationRefresh: any;
}
