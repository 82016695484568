import React, { useEffect, useState } from 'react';
import {
	Col,
	Row,
	Form,
	Select,
	TreeSelect,
	Tabs,
	Divider,
	Space,
	Button,
	Modal,
	Input,
} from 'antd';
import styles from './index.module.scss';
// import { PlusOutlined } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import CapQbLogo from 'components/settings/Configurations/CapQbLogo';
import { PlusOutlined } from '@ant-design/icons';
import { CloseSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import { postApi } from 'redux/apis';
import { getEmployeeAction } from 'redux/action/employeeAction';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

// Define types for component props
interface DataMappingFormProps {
	handleHeaderMappingForm: () => void;
	handleBack: () => void;
	headers: string[];
	handleTreeSelectChange: (field: string, value: string, data: any) => void;
	handleEmployeeSelectChange: (field: string, value: string, data: any) => void;
	handleCustomerTreeSelectChange: (
		field: string,
		value: string,
		data: any
	) => void;
	classList: any[];
	isTimeActivityChecked: boolean;
	employees: any[];
	customerOptions: any[];
	fileClassValues: { key: string; selectedValue: string }[];
	fileEmployeeValues: { key: string; selectedValue: string }[];
	fileCustomerValues: { key: string; selectedValue: string }[];
}

const DataMappingForm: React.FC<DataMappingFormProps> = ({
	handleHeaderMappingForm,
	handleBack,
	fileClassValues,
	fileEmployeeValues,
	isTimeActivityChecked,
	fileCustomerValues,
	handleTreeSelectChange,
	handleEmployeeSelectChange,
	handleCustomerTreeSelectChange,
	classList,
	employees,
	customerOptions,
}) => {
	const [isNewEmployeeModalOpen, setIsNewEmployeeModalOpen] = useState(false);
	const [isCreateEmployeeLoading, setIsCreateEmployeeLoading] = useState(false);
	const [tempEmployeeNameValue, setTempEmployeeNameValue] =
		useState<string>('');
	const dispatch = useDispatch<AppDispatch>();

	const [form] = Form.useForm();

	useEffect(() => {
		dispatch(getEmployeeAction());
		console.log('employees', employees);
		form.setFieldsValue({ employeeName: tempEmployeeNameValue });
	}, [tempEmployeeNameValue, form]);

	const handleModalClose = () => {
		setIsNewEmployeeModalOpen(false);
	};

	const validateEmployeesMapping = () => {
		const unmappedEmployees = fileEmployeeValues.filter(
			(item) => !item.selectedValue
		);
		return unmappedEmployees.length === 0;
	};

	const handleNextButtonClick = () => {
		// Perform validation before calling the parent function
		if (validateEmployeesMapping()) {
			handleHeaderMappingForm(); // Call the parent function to proceed
		} else {
			toastText('Please map all employees before proceeding.', 'error');
			// Show error if validation fails
		}
	};

	const handleSubmit = async (values: any) => {
		try {
			setIsCreateEmployeeLoading(true);
			await postApi(`/quickbooks/employees/create`, {
				companyId: localStorage.getItem('companyId'),
				employeeName: values.employeeName,
			});
			toastText('New QuickBooks employee created successfully', 'success');
			await dispatch(getEmployeeAction());
			setIsCreateEmployeeLoading(false);
			handleModalClose();
		} catch (err: any) {
			if (err?.response?.data?.message) {
				toastText(err?.response?.data?.message, 'error');
			} else {
				toastText(
					'Something went wrong in creating new QuickBooks employee',
					'error'
				);
			}
			setIsCreateEmployeeLoading(false);
		}
	};

	return (
		<div>
			<Form className={styles['custom-form']}>
				<div className={styles['configuration-settings--logos']}>
					<CapQbLogo />
				</div>

				<Tabs
					defaultActiveKey="1"
					className={styles['fixed-tabs']}
				>
					{isTimeActivityChecked && (
						<>
							{fileClassValues?.length > 0 ? (
								<TabPane
									tab="Class"
									key="1"
								>
									<div className={styles['tab-content']}>
										<Row
											className={styles['time-sheet-modal__content']}
											style={{ marginBottom: '10px', marginTop: '10px' }}
										>
											<Col
												span={24}
												style={{ fontWeight: 600, fontSize: '2rem' }}
											>
												Class
											</Col>
										</Row>
										{fileClassValues.map((item) => (
											<Row
												key={item.key}
												style={{ marginTop: '15px' }}
											>
												<Col span={8}>{item.key}</Col>
												<Col span={8}></Col>
												<Col span={8}>
													<TreeSelect
														showSearch
														className={styles['search-filter__filter']}
														style={{ width: '100%' }}
														onChange={(value, data) =>
															handleTreeSelectChange(item.key, value, data)
														}
														value={item.selectedValue}
														placeholder="Class"
														allowClear={false}
														treeDefaultExpandAll
														size="large"
														treeData={classList}
														filterTreeNode={(inputValue, treeNode) =>
															treeNode.props.title
																.toLowerCase()
																.includes(inputValue.toLowerCase())
														}
													/>
												</Col>
											</Row>
										))}
									</div>
								</TabPane>
							) : (
								<TabPane
									tab="Class"
									key="1"
								>
									<div className={styles['tab-content']}>
										<Row style={{ marginTop: '15px' }}>
											<Col
												span={24}
												style={{ textAlign: 'center', color: 'red' }}
											>
												No Class data found.
											</Col>
										</Row>
									</div>
								</TabPane>
							)}

							{fileCustomerValues?.length > 0 ? (
								<TabPane
									tab="Customer"
									key="3"
								>
									<div className={styles['tab-content']}>
										<Row
											className={styles['time-sheet-modal__content']}
											style={{ marginBottom: '10px', marginTop: '10px' }}
										>
											<Col
												span={24}
												style={{ fontWeight: 600, fontSize: '2rem' }}
											>
												Customer
											</Col>
										</Row>
										{fileCustomerValues.map((item) => (
											<Row
												key={item.key}
												style={{ marginTop: '15px' }}
											>
												<Col span={8}>{item.key}</Col>
												<Col span={8}></Col>
												<Col span={8}>
													<TreeSelect
														showSearch
														className={styles['search-filter__filter']}
														style={{ width: '100%' }}
														onChange={(value, data) =>
															handleCustomerTreeSelectChange(
																item.key,
																value,
																data
															)
														}
														value={item.selectedValue}
														placeholder="Customer"
														allowClear={false}
														treeDefaultExpandAll
														size="large"
														treeData={customerOptions}
														filterTreeNode={(inputValue, treeNode) =>
															treeNode.props.title
																.toLowerCase()
																.includes(inputValue.toLowerCase())
														}
													/>
												</Col>
											</Row>
										))}
									</div>
								</TabPane>
							) : (
								<TabPane
									tab="Customer"
									key="3"
								>
									<div className={styles['tab-content']}>
										<Row style={{ marginTop: '15px' }}>
											<Col
												span={24}
												style={{ textAlign: 'center', color: 'red' }}
											>
												No Customer data found.
											</Col>
										</Row>
									</div>
								</TabPane>
							)}
						</>
					)}

					{fileEmployeeValues?.length > 0 ? (
						<TabPane
							tab={
								<>
									Employee{' '}
									<span
										style={{
											color: fileEmployeeValues.some(
												(item) => !item.selectedValue
											)
												? 'red'
												: 'green',
											fontWeight: 'bold',
											fontSize: '13px',
										}}
									>
										(
										{
											fileEmployeeValues.filter((item) => !item.selectedValue)
												.length
										}{' '}
										Unmapped)
									</span>
								</>
							}
							key="2"
						>
							<div className={styles['tab-content']}>
								<Row
									className={styles['time-sheet-modal__content']}
									style={{ marginBottom: '10px', marginTop: '10px' }}
								>
									<Col
										span={24}
										style={{ fontWeight: 600, fontSize: '2rem' }}
									>
										Employee
									</Col>
								</Row>
								{fileEmployeeValues.map((item) => (
									<Row
										key={item.key}
										style={{
											marginTop: '15px',
											backgroundColor: !item.selectedValue
												? '#ffe6e6'
												: 'transparent', // Change background color if there's an error
										}}
									>
										<Col
											span={8}
											style={{
												display: 'flex', // Use flexbox for layout
												alignItems: 'center',
												paddingLeft: '10px', // Vertically center content
											}}
										>
											{item.key}
										</Col>
										<Col span={8}></Col>
										<Col span={8}>
											<Select
												className={styles['search-filter__filter']}
												style={{
													width: '100%',
												}}
												onChange={(value, data) =>
													handleEmployeeSelectChange(item.key, value, data)
												}
												size="large"
												placeholder="Employee"
												value={item?.selectedValue}
												showSearch
												filterOption={(input, option) =>
													(option?.children as unknown as string)
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
												dropdownRender={(menu) => (
													<>
														{menu}
														<Divider style={{ margin: '8px 0' }} />
														<Space style={{ padding: '0 8px 4px' }}>
															<Button
																type="text"
																icon={<PlusOutlined />}
																onClick={() => {
																	console.log(item.key);
																	setIsNewEmployeeModalOpen(true);
																	setTempEmployeeNameValue(item.key);
																}}
															>
																Add new QuickBooks Employee
															</Button>
														</Space>
													</>
												)}
											>
												<Select.Option value="">Select Employee</Select.Option>
												{employees?.map((employee, index) => (
													<Select.Option
														value={employee?.id}
														key={index}
													>
														{employee?.fullName}
													</Select.Option>
												))}
											</Select>
										</Col>
									</Row>
								))}
							</div>
						</TabPane>
					) : (
						<TabPane
							tab="Employee"
							key="2"
						>
							<div className={styles['tab-content']}>
								<Row style={{ marginTop: '15px' }}>
									<Col
										span={24}
										style={{ textAlign: 'center', color: 'red' }}
									>
										No Employee data found.
									</Col>
								</Row>
							</div>
						</TabPane>
					)}
				</Tabs>

				<Row
					style={{
						marginTop: '20px',
						display: 'flex',
						justifyContent: 'flex-start',
					}}
				>
					<button
						key="cancel"
						onClick={handleBack}
						className={`${styles['confirm-delete-model__button--cancel']} ${styles['confirm-delete-model__button--btn']}`}
						style={{ marginRight: '8px' }} // Add space between buttons
					>
						Back
					</button>
					<button
						onClick={handleNextButtonClick}
						className="btn-black"
					>
						Next
					</button>
				</Row>
			</Form>

			{isNewEmployeeModalOpen && (
				<div className="qbo-account">
					<Modal
						open={isNewEmployeeModalOpen}
						footer={false}
						style={{ top: 20 }}
						onCancel={handleModalClose}
						className="qbo-account-modal"
						closable={false}
					>
						<Row className={styles['qbo-modal__header']}>
							<div className="userDetailsTitle">
								<p>Add New QuickBooks Employee</p>
							</div>
							<div
								className={styles['qbo-modal__header-close']}
								onClick={handleModalClose}
							>
								<CloseSvg />
							</div>
						</Row>
						<hr />
						<Form
							name="basic"
							wrapperCol={{ span: 16 }}
							style={{ maxWidth: 600 }}
							autoComplete="off"
							form={form}
							labelCol={{ span: 8 }}
							onFinish={handleSubmit}
						>
							<div className="qbo-account-form">
								<Form.Item
									label="Employee Name"
									name="employeeName"
									rules={[
										{ required: true, message: 'Please enter Employee name!' },
									]}
								>
									<Input
										size="large"
										className="qbo-account-form__field-input"
										value={tempEmployeeNameValue}
										onChange={(e) => setTempEmployeeNameValue(e.target.value)}
									/>
								</Form.Item>
							</div>

							<hr />

							<Row
								justify="start"
								className={`${styles['buttons']}`}
								gutter={16}
							>
								<Col
									xs={12}
									md={7}
									lg={7}
									sm={8}
								>
									<button
										className={`${styles['save']}`}
										type="submit"
										disabled={isCreateEmployeeLoading}
										// onClick={handleSubmit}
									>
										{isCreateEmployeeLoading ? (
											<img
												src="/assets/gifs/loading-black.gif"
												height={40}
											/>
										) : (
											'Save'
										)}
									</button>
								</Col>
								<Col
									xs={12}
									md={7}
									lg={7}
									sm={8}
								>
									<button
										className={styles['cancel']}
										onClick={handleModalClose}
									>
										Cancel
									</button>
								</Col>
							</Row>
						</Form>
					</Modal>
				</div>
			)}
		</div>
	);
};

export default DataMappingForm;
