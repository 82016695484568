/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Checkbox, Image, Input, Select, Table, Tooltip } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getEmployeeCostAction,
	paginateEmployeeCostAction,
	updateEmployeeCost,
} from 'redux/action/employeeCostAction';
import { getEmployeeCostColumnAction } from 'redux/action/employeeCostColumnSlice';
import { AppDispatch } from 'redux/store';
import {
	ClockSvg,
	QuickbooksEmployee,
	SortSvgBottom,
	SortSvgTop,
	SyncNowSvg,
} from 'utils/svgs';
import {
	checkPermission,
	convertToHyphenCase,
	hasText,
	toastText,
} from 'utils/utils';
import SearchAndFilter from '../SearchAndFilter';
import styles from './index.module.scss';
import './index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { payPeriodOptionsAction } from 'redux/action/payPeriodAction';
import {
	clearEmployeeCostRedux,
	updateEmployeeCostValue,
} from 'redux/slice/employeeCostSlice';
import { Loader } from 'components/Global';
import { useDebounce } from 'use-debounce';
import { getApi } from 'redux/apis';
import { NumericFormat } from 'react-number-format';
import dayjs from 'dayjs';
import MessageModal from 'components/Global/MessageModal';
import { getConfiguration } from 'redux/action/configurationAction';
import { EmployeeConfigModal } from '../EmployeeConfigModal';
import { customerOptionsAction } from 'redux/action/customerAction';
import { classAction } from 'redux/action/classAction';
import { PercentageOutlined } from '@ant-design/icons';
import { ImportEmployeeCostCsvModal } from '../ImportEmployeeCostValueModal';
import { HeaderValueToggleModal } from './WarningModal';

const TestTable = (props: any) => {
	const {
		onChangePayPeriod,
		onChangeSearchValue,
		handleInactive,
		includeInactive,
		onChangeCostValue,
		showEmployeeWithAllocationConfig,
		onChangeShowEmployeeWithAllocationConfig,
		openEmployeeCostModal,
		onCloseEmployeeCostModal,
		handleShowPayPeriodCreationRefresh,
		showPayPeriodCreationRefresh,
		handleShowEmployeeCostRefresh,
		showEmployeeCostRefresh,
	} = props;

	const qboMode = process.env.REACT_APP_QBO_MODE
		? process.env.REACT_APP_QBO_MODE
		: 'sandbox';

	const { Column } = Table;
	const dispatch = useDispatch<AppDispatch>();

	const navigate = useNavigate();

	const {
		data,
		count,
		payPeriodId: currentDatePayPeriodId,
		hasEmployeeData,
		notIncludedEmployeeIds,
	} = useSelector((state: any) => state?.employeeCosts);
	const { data: sectionWiseFields } = useSelector(
		(state: any) => state?.employeeCostColumns
	);

	const payrollMethod = useSelector(
		(state: any) => state?.companies?.configurations?.payrollMethod
	);

	const { closingDate } = useSelector((state: any) => state?.closingDate);

	const [queryParams, setQueryParams] = useSearchParams();

	const [payPeriodId, setPayPeriodId] = useState<string | null>(
		queryParams.get('payPeriodId') || localStorage.getItem('payPeriod')
	);
	const [isNewPayPeriod, setIsNewPayPeriod] = useState<string | null>(
		queryParams.get('isNewPayPeriod')
	);

	// const tableRef = useRef<HTMLDivElement>(null);
	const finalTotalColumnId = useRef(Math.random());
	const [isInViewPort, setIsInViewPort] = useState<boolean>(false);
	const [employeeData, setEmployeeData] = useState<any[]>([]);

	const [columns, setColumns] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchValue, setSearchValue] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [isProceedLoading, setIsProceedLoading] = useState(false);

	const [isConfigModalOpen, setISConfigModalOpen] = useState(false);
	const [selectedEmployeeConfig, setSelectedEmployeeConfig] =
		useState<any>(null);

	const [openValueUpdateModal, setOpenValueUpdateModal] = useState(false);
	const [isChecked, setIsChecked] = useState<{ [key: string]: boolean }>({});
	const [isTempChecked, setIsTempChecked] = useState<boolean>(false);

	// Type for currentColumnKey: either a string or null
	const [currentColumnKey, setCurrentColumnKey] = useState<string | null>(null);
	const [sectionId, setSectionId] = useState<string | null>(null);

	const [headerData, setHeaderData] = useState<{
		header: string;
		text: string | null;
	}>({
		header: '',
		text: null,
	});
	const [debounceSearchValue] = useDebounce(searchValue, 1000);

	const { optionData: payPeriods, isPayPeriodOptionFetched } = useSelector(
		(state: any) => state?.payPeriods
	);
	const isPayPeriodLength: any = useSelector(
		(state: any) => state?.payPeriods?.isPayPeriodLength
	);

	const [isAccountClosed, setIsAccountClosed] = useState<boolean>(false);
	const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

	const [totalCostRow, setTotalCostRow] = useState<any>(null);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const { data: configurationsData } = useSelector(
		(state: any) => state?.configuration
	);

	const isEditEmployeeCostPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Employee Cost',
			permission: ['edit'],
		}
	);

	const isConfigurationPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Configurations',
		permission: ['edit', 'view', 'add', 'delete'],
	});

	const [applySearch, setApplySearch] = useState(false);

	const [sort, setSort] = useState('asc');

	const colorArray = ['cell-fff', 'cell-f2f2f2', 'cell-e7eff8', 'cell-f3ede7'];

	const sortData = {
		showSorterTooltip: { title: '' },
		defaultSortOrder: 'ascend',
		sorter: () => {
			return null as any;
		},
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortIcon: (data: any) => {
			return data.sortOrder === 'ascend' ? <SortSvgTop /> : <SortSvgBottom />;
		},
	};

	// Get total employee-cost total

	const fetchEmployeeCostTotal: any = async () => {
		try {
			const query: any = {
				companyId: localStorage.getItem('companyId'),
				payPeriodId: payPeriodId,
				search: searchValue,
				includeInactive,
				showEmployeeWithAllocationConfig,
			};

			if (!hasText(searchValue) || searchValue.toString().length < 3) {
				delete query.search;
			}

			const employeeCostData = await getApi(`/employee-cost/total`, query);
			return employeeCostData?.data?.data;
		} catch (error) {
			navigate('/unauthorized');
		}
	};

	const closeModal = () => {
		setIsMessageModalOpen(false);
	};

	const openModal = () => {
		setIsMessageModalOpen(true);
	};

	useEffect(() => {
		if (
			isPayPeriodLength == false &&
			localStorage.getItem('settings') === null
		) {
			openModal();
		}
	}, [isPayPeriodLength]);

	useEffect(() => {
		// dispatch(getEmployeeCostColumnAction()).then(() => {
		// 	// createFields();
		// });
		return () => {
			dispatch(clearEmployeeCostRedux());
		};
	}, []);

	useEffect(() => {
		if (notIncludedEmployeeIds) {
			setApplySearch(true);
		}
	}, [notIncludedEmployeeIds]);

	useEffect(() => {
		if (payPeriodId) {
			dispatch(
				getConfiguration({
					payPeriodId,
				})
			);
			dispatch(
				getEmployeeCostColumnAction({
					payPeriodId,
				})
			);
		}

		// if (payPeriodId) {
		// }
	}, [payPeriodId]);

	useEffect(() => {
		if (selectedCompanyDetails && payPeriods && isPayPeriodOptionFetched) {
			let _payPeriodId: string | null = null;
			if (payPeriods.length) {
				if (
					payPeriods[payPeriods.length - 1].companyId ===
					selectedCompanyDetails.companyId
				) {
					_payPeriodId = payPeriodId;
					if (_payPeriodId) {
						const payPeriodData = payPeriods.find(
							(e: any) => e.id === payPeriodId
						);
						if (
							!payPeriodData ||
							payPeriodData.companyId != selectedCompanyDetails.companyId
						) {
							_payPeriodId = payPeriods[payPeriods.length - 1]?.id || null;
							// setPayPeriodId(payPeriods[payPeriods.length - 1]?.id || null);
						}
					} else {
						// setPayPeriodId(payPeriods[payPeriods.length - 1]?.id || null);
						_payPeriodId = payPeriods[payPeriods.length - 1]?.id || null;
					}
					if (_payPeriodId) {
						const _query: any = {
							payPeriodId: _payPeriodId as string,
						};

						if (queryParams.get('isNewPayPeriod') === 'true') {
							_query.isNewPayPeriod = 'true';
						}

						setQueryParams(_query);
						localStorage.setItem('payPeriod', _payPeriodId as string);
					}
					setApplySearch(true);
					setPayPeriodId(_payPeriodId);
					onChangePayPeriod && onChangePayPeriod(_payPeriodId);
					setTotalCostRow(null);
				}
			} else {
				if (_payPeriodId) {
					const _query: any = {
						payPeriodId: _payPeriodId as string,
					};

					if (queryParams.get('isNewPayPeriod') === 'true') {
						_query.isNewPayPeriod = 'true';
					}
					setQueryParams(_query);
					localStorage.setItem('payPeriod', _payPeriodId as string);
				}
				setApplySearch(true);
				onChangePayPeriod && onChangePayPeriod(_payPeriodId);
				setTotalCostRow(null);
			}
		}

		if (selectedCompanyDetails?.companyId) {
			dispatch(classAction());
			dispatch(customerOptionsAction());
		}
	}, [selectedCompanyDetails, payPeriods, isPayPeriodOptionFetched]);

	useEffect(() => {
		if (payPeriodId && payPeriods) {
			const payPeriodData = payPeriods.find((e: any) => e.id === payPeriodId);
			if (payPeriodData && closingDate) {
				if (dayjs(payPeriodData.endDate) < dayjs(closingDate).endOf('day')) {
					setIsAccountClosed(true);
				} else {
					setIsAccountClosed(false);
				}
			}
		}
	}, [payPeriodId, payPeriods, closingDate]);

	useEffect(() => {
		const finalColumns: any = [
			{
				isSectionLastColumn: true,
				className: 'employee-name',
				cellColor: 'cell-fff',
				sectionId: '',
				sectionName: '',
				fieldId: '',
				fieldName: 'Employee Name',
				inputNumberField: 'Text',
				isCalculatorNeeded: false,
				isDollarNeeded: false,
				isHours: false,
				total: false,
				title: 'Employee Name',
				dataIndex: 'employeeName',
				key: Math.random(),
				width: 200,
				isHoursSetup: false,
				...sortData,
			},
		];

		// (payrollMethod === 'Percentage' && singleSection.no === 0)

		sectionWiseFields?.map((singleSection: any, sectionIndex: number) => {
			singleSection?.fields?.map((singleField: any, index: number) => {
				if (singleSection.no != 0 && singleField.isActive) {
					finalColumns.push({
						svg:
							sectionIndex === 0 && (index === 1 || index === 2) ? (
								<ClockSvg />
							) : null,
						cellWidth:
							sectionIndex === 0 && (index === 1 || index === 2) ? 130 : 90,
						className: convertToHyphenCase(singleField.name),
						sectionId: singleSection?.id,
						sectionName: singleSection?.sectionName,
						fieldId: singleField?.id,
						fieldName: singleField?.name,
						inputNumberField:
							singleField?.name == 'Employee Type'
								? 'Select'
								: singleField.jsonId.charAt(0) === 't'
								? 'Text'
								: 'Number',
						isTotalField: singleField.jsonId.charAt(0) === 't',
						isCalculatorNeeded:
							singleSection?.sectionName == 'Employee Type' ||
							payrollMethod === 'Percentage'
								? false
								: true,
						isDollarNeeded:
							singleSection?.sectionName == 'Employee Type' ? false : true,
						isHours:
							singleField?.name == 'Maximum allocate hours per year'
								? true
								: singleField?.name == 'Maximum Vacation/PTO hours per year'
								? true
								: false,
						total: false,
						title: singleField?.name,
						dataIndex: singleField?.id,
						cellColor: colorArray[sectionIndex],
						isSectionLastColumn:
							index === singleSection?.fields.length - 1 ? true : false,
						key: singleField?.id,
						width:
							singleField?.name == 'Employee Type'
								? 250
								: singleField?.name == 'Maximum allocate hours per year'
								? 210
								: singleField?.name == 'Maximum Vacation/PTO hours per year'
								? 250
								: 140,
						status: singleField?.active,

						isHoursSetup: singleSection?.no == 0,
						// isCheckedField:
						// 	isChecked[singleField?.id] || singleField?.isCheckedField,
						isCheckedField: isChecked[singleField?.id],
					});
				}
			});
		});

		const totalColumn = {
			isSectionLastColumn: false,
			cellColor: 'cell-fff',
			className: 'totalLabourBurden',
			sectionId: '',
			sectionName: '',
			fieldId: '',
			fieldName: 'Total Labor Burden',
			inputNumberField: 'Text',
			isCalculatorNeeded: false,
			isDollarNeeded: false,
			isHours: false,
			total: true,
			title: 'Total Labor Burden',
			dataIndex: 'totalLaborBurden',
			key: finalTotalColumnId,
			width: 200,
			isHoursSetup: false,
			isTotalField: true,
		};

		finalColumns.push(totalColumn);

		setColumns(finalColumns);
	}, [sectionWiseFields, isChecked]);

	useEffect(() => {
		sectionWiseFields?.map((singleSection: any, sectionIndex: number) => {
			singleSection?.fields?.map((singleField: any, index: number) => {
				setIsChecked((prev) => {
					return {
						...prev,
						[singleField?.id]: singleField?.isCheckedField,
					};
				});
			});
		});
	}, [sectionWiseFields]);
	// useEffect(() => {
	// 	dispatch(payPeriodOptionsAction());
	// }, []);

	useEffect(() => {
		if (applySearch) {
			getEmployeeCostData();
		}
	}, [
		debounceSearchValue,
		payPeriodId,
		applySearch,
		payPeriodId,
		includeInactive,
		showEmployeeWithAllocationConfig,
		notIncludedEmployeeIds,
	]);

	// useEffect(() => {
	// 	if (applySearch && searchValue.length > 3) {
	// 		getEmployeeCostData();
	// 	}
	// }, [debounceSearchValue]);

	useEffect(() => {
		if (data && sectionWiseFields) {
			if (currentDatePayPeriodId) {
				setPayPeriodId(currentDatePayPeriodId);

				const _query: any = {
					payPeriodId: currentDatePayPeriodId as string,
				};

				if (queryParams.get('isNewPayPeriod') === 'true') {
					_query.isNewPayPeriod = 'true';
				}

				setQueryParams(_query);
				localStorage.setItem('payPeriod', currentDatePayPeriodId);
				onChangePayPeriod && onChangePayPeriod(currentDatePayPeriodId);
			}
			finalCalculation(data);
			getTotalCostValueRow();
			return;
		}

		setEmployeeData([]);
		setTotalCostRow(null);
	}, [data, sectionWiseFields]);

	// Pagination
	useEffect(() => {
		const tableBody = document.querySelector('.ant-table-body');
		tableBody?.addEventListener('scroll', scrollHandler);
		return () => {
			// Remove scroll event listener when component unmounts
			tableBody?.removeEventListener('scroll', scrollHandler);
		};
	}, [data]);

	useEffect(() => {
		(async () => {
			if (isInViewPort && employeeData.length < count) {
				setCurrentPage((prev: any) => prev + 1);

				const query = {
					page: currentPage + 1,
					limit: 10,
					search: searchValue,
					sort: sort,
					payPeriodId,
					includeInactive,
					showEmployeeWithAllocationConfig,
				};

				await dispatch(paginateEmployeeCostAction(query));
			}
		})();
	}, [isInViewPort, payPeriodId]);

	// useEffect(() => {
	// 	getEmployeeCostData();
	// }, [isChecked, currentColumnKey]);

	const getEmployeeCostData = async () => {
		const query: any = {
			payPeriodId,
			page: 1,
			limit: 10,
			sort: sort,
			includeInactive,
			showEmployeeWithAllocationConfig,
			search: searchValue,
			notIncludedEmployeeIds: notIncludedEmployeeIds?.join(','),
		};

		if (!hasText(searchValue) || searchValue.toString().length < 3) {
			delete query.search;
		}

		if (!payPeriodId) {
			delete query.payPeriodId;
		}

		setIsLoading(true);
		const res = await dispatch(getEmployeeCostAction(query));

		setIsLoading(false);
		if (res?.payload?.error) {
			if (res?.payload?.error?.message === 'Invalid PayPeriod') {
				// navigate('/unauthorized');
				navigate('/login');
			}
		}

		setApplySearch(false);

		setCurrentPage(1);
	};

	//adding body to scroll to table body Infinite scroll
	const scrollHandler = useCallback((event: any) => {
		const { currentTarget } = event;
		const tableBody = currentTarget?.querySelector('tbody');
		if (
			tableBody?.getBoundingClientRect().top +
				tableBody.getBoundingClientRect().height <
			screen.height
		) {
			setIsInViewPort(true);
		} else {
			setIsInViewPort(false);
		}
	}, []);

	const handleChangeCostValue = (index: number, key: string, value: string) => {
		const tempCopy = JSON.parse(JSON.stringify(employeeData));
		tempCopy[index][key] = hasText(value) ? value : 0;
		dispatch(updateEmployeeCostValue({ index, key, value }));
		setEmployeeData(tempCopy);
	};

	const changeHandler = async (
		index: number,
		key: string,
		value: string,
		method: string,
		isFromCalculator: boolean
	) => {
		// if (payrollMethod === 'Percentage') {
		const tempCopy = JSON.parse(JSON.stringify(employeeData));
		//calculation of fields
		let total = 0;
		const sectionKey = tempCopy[index][`section_${key}`];
		const section = sectionWiseFields.find(
			(singleSection: any) => singleSection.id === sectionKey
		);
		const totalCostSectionValueField = section.fields.find(
			(singleField: any) => singleField.jsonId === 't1'
		);
		for (const sectionField of section.fields) {
			if (sectionField.jsonId !== 't1' && sectionField.isActive) {
				total += Number(tempCopy[index][sectionField.id]);
			}
		}
		tempCopy[index][totalCostSectionValueField.id] = total.toFixed(
			configurationsData?.decimalToFixedAmount || 2
		);
		dispatch(
			updateEmployeeCostValue({
				index,
				key: totalCostSectionValueField.id,
				value: total.toFixed(configurationsData?.decimalToFixedAmount || 2),
			})
		);
		finalCalculation(tempCopy);
		await dispatch(
			updateEmployeeCost({
				employeeCostValueID: tempCopy[index][`value_${key}`],
				value:
					Number(value).toFixed(
						configurationsData?.decimalToFixedAmount || 2
					) || '0.00',
				isCalculatorValue: isFromCalculator,
			})
		);
		await dispatch(
			updateEmployeeCost({
				employeeCostValueID:
					tempCopy[index][`value_${totalCostSectionValueField.id}`],
				value:
					Number(total).toFixed(
						configurationsData?.decimalToFixedAmount || 2
					) || '0.00',
				isCalculatorValue: isFromCalculator,
			})
		);
		await getTotalCostValueRow();

		const currentYear = new Date().getFullYear();
		await getApi('/dashboard/salary-by-customer-lambda-trigger', {
			companyId: localStorage.getItem('companyId'),
			year: currentYear,
		});
	};

	// For perform the sorting operation
	const performSortHandler = (type: string) => {
		setSort(type === 'ascend' ? 'asc' : 'desc');
		setCurrentPage(1);
		dispatch(
			getEmployeeCostAction({
				page: 1,
				limit: 10,
				sort: type === 'ascend' ? 'asc' : 'desc',
				payPeriodId,
				includeInactive,
				showEmployeeWithAllocationConfig,
				notIncludedEmployeeIds: notIncludedEmployeeIds?.join(','),
			})
		);
	};

	// For handle the table change click
	const tableChangeHandler = (_: any, __: any, data: any) => {
		performSortHandler && performSortHandler(data.order);
	};

	//  for calculation of lastColumn
	const finalCalculation = async (_employeeData: any) => {
		const employeeDataCopy = JSON.parse(JSON.stringify(_employeeData));
		const fieldArray: any = [];
		sectionWiseFields.forEach((singleSection: any) => {
			if (singleSection.no !== 0) {
				// const fieldArray: any = [];
				singleSection.fields.forEach((singleField: any) => {
					if (singleField.jsonId === 't1') {
						fieldArray.push(singleField.id);
					}
				});
				// fieldObject[index] = fieldArray;
			}
		});
		_employeeData.forEach((singleEmployee: any, index: number) => {
			let total = 0;
			fieldArray.forEach((singleField: any) => {
				total += Number(singleEmployee[singleField] || 0);
			});
			employeeDataCopy[index]['totalLaborBurden'] = total.toFixed(
				configurationsData?.decimalToFixedAmount || 2
			);
		});
		setEmployeeData(employeeDataCopy);
	};

	const getTotalCostValueRow = async () => {
		if (payPeriodId) {
			const totalCost = await fetchEmployeeCostTotal();
			if (totalCost && Object.keys(totalCost).length > 3) {
				setTotalCostRow(totalCost);
				return;
			}
			setTotalCostRow(null);
		}
	};

	const handleEmployeeModalClose = async () => {
		onCloseEmployeeCostModal && onCloseEmployeeCostModal(false);
		await getEmployeeCostData();
	};

	const handleCheckboxChange = (singleColumn: any, checked: boolean) => {
		const { key, sectionId, fieldName } = singleColumn;
		setCurrentColumnKey(key);
		setSectionId(sectionId);
		setIsTempChecked(checked);

		setHeaderData({
			header: fieldName,
			text: checked ? 'negative' : 'positive',
		});
		setOpenValueUpdateModal(true);
	};

	const handleProceed = async () => {
		setIsProceedLoading(true);
		try {
			if (currentColumnKey) {
				setIsChecked((prev) => ({
					...prev,
					[currentColumnKey]: isTempChecked,
				}));

				const query: any = {
					companyId: localStorage.getItem('companyId'),
					payPeriodId: payPeriodId,
					employeeFieldId: currentColumnKey,
					isNegativeValue: isTempChecked,
					sectionId: sectionId,
				};

				// Make the API call
				await getApi('/employee-cost/update-checkfield', query);
				setColumns((prevColumns: any) =>
					prevColumns.map((column: any) =>
						column.key === currentColumnKey
							? { ...column, isCheckedField: isTempChecked }
							: column
					)
				);
			}
		} catch (error) {
			console.log('Error in handleProceed:', error);
		} finally {
			await getEmployeeCostData();
			setIsProceedLoading(false);
			setOpenValueUpdateModal(false);
			setCurrentColumnKey(null);
			setIsTempChecked(false);
			setHeaderData({ header: '', text: null });
		}
	};

	const handleCancel = () => {
		setOpenValueUpdateModal(false);
		setCurrentColumnKey(null);
		setIsTempChecked(false);
		setHeaderData({ header: '', text: null });
		getEmployeeCostData();
	};

	const [seconds, setSeconds] = useState(60); // Starting countdown value
	/* 	const [timerId, setTimerId] = useState(null);
	const [showTempProcessRefreshFlag, setShowTempProcessRefreshFlag] =
		useState(true); */

	/* 	const startTimer = () => {
		if (timerId) {
			clearInterval(timerId); // Clear any existing timer
		}
		const newTimerId = setInterval(() => {
			setSeconds((prevSeconds) => {
				if (!showTempProcessRefreshFlag) {
					clearInterval(newTimerId);
					return prevSeconds;
				}

				if (prevSeconds === 0) {
					clearInterval(newTimerId); // Stop the timer when it reaches 0
					return 0;
				}

				if (
					seconds > 0 &&
					prevSeconds <= 40 &&
					prevSeconds % 10 === 0 &&
					showPayPeriodCreationRefresh &&
					!showEmployeeCostRefresh
				) {
					debugger;
					handlePayPeriodRefresh();
					handleShowPayPeriodCreationRefresh(false);
					setShowTempProcessRefreshFlag(false);y
					dispatch(
						getEmployeeCostAction({
							page: 1,
							limit: 10,
							sort: 'asc',
							payPeriodId: payPeriodId,
							includeInactive,
						})
					);
					getTotalCostValueRow();
					setSeconds(0);
					return 0;
				}

				if (
					seconds > 0 &&
					prevSeconds <= 40 &&
					prevSeconds % 10 === 0 &&
					!showPayPeriodCreationRefresh &&
					showTempProcessRefreshFlag &&
					showEmployeeCostRefresh
				) {
					handleEmployeeCostRefresh();
					handleShowEmployeeCostRefresh(false);
					setShowTempProcessRefreshFlag(false);
					setSeconds(0);
					return 0;
				}

				return prevSeconds - 1;
			});
		}, 1000);

		setTimerId(newTimerId as any); // Store the timer ID for clearing later
	};

	// Call startTimer when the component mounts (simulating componentDidMount)
	useEffect(() => {
		if (
			seconds === 60 &&
			!timerId &&
			(showPayPeriodCreationRefresh || showEmployeeCostRefresh)
		) {
			setSeconds(60);
			// You can adjust this logic as per your needs
			startTimer();
		}
	}, [showPayPeriodCreationRefresh, showEmployeeCostRefresh]); */

	const handlePayPeriodRefresh = async () => {
		const response = await getApi(
			'/employee-cost/importEmployeeCost-import-status',
			{
				companyId: localStorage.getItem('companyId'),
				type: 'Pay Period Creation',
			}
		);

		if (response.data.data) {
			if (response.data.data.status === 1) {
				handleShowPayPeriodCreationRefresh(false);
				toastText(response.data.data.message, 'success');

				setIsLoading(false);

				// const _query: any = {
				// 	openDrawer: 'true',
				// 	activeKey: 'Configurations',
				// };

				// if (queryParams.get('payPeriodId')) {
				// 	_query.payPeriodId = payPeriodId;
				// }

				// if (queryParams.get('isNewPayPeriod') === 'true') {
				// 	_query.isNewPayPeriod = 'true';
				// }

				// setQueryParams(_query);
			}
		}
	};

	/* const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds
	const [status, setStatus] = useState('pending'); // can be 'pending', 'completed'
	const [message, setMessage] = useState(''); // Dynamic message

	// Effect to handle the countdown timer and periodic status check
	useEffect(() => {
		if (timeLeft > 0 && status !== 'completed') {
			const timerId = setInterval(() => {
				if (showPayPeriodCreationRefresh) {
					handleEmployeeCostRefresh();
				}
				// Check the status and refresh
				setTimeLeft((prevTime) => prevTime - 10); // Decrease time by 10 seconds every interval
			}, 10000); // 10 seconds interval for checking status

			return () => clearInterval(timerId); // Cleanup on unmount
		} else if (timeLeft <= 0 && status !== 'completed') {
			setStatus('completed');
			setMessage('Your request is completed.');
		}
	}, [timeLeft, status]);

	// Update the message dynamically based on status and time left
	useEffect(() => {
		if (status === 'pending') {
			setMessage(
				`Your request is being processed. It will be completed in ${timeLeft} seconds.`
			);
		} else if (status === 'completed') {
			setMessage('Your request has been successfully completed.');
		}
	}, [status, timeLeft]); */

	const handleEmployeeCostRefresh = async () => {
		const response = await getApi(
			'/employee-cost/importEmployeeCost-import-status',
			{
				companyId: localStorage.getItem('companyId'),
				type: 'Import Employee Cost',
			}
		);

		if (response.data.data) {
			console.log('response.data.data.status: ', response.data.data.status);
			if (response.data.data.status === 1) {
				handleShowEmployeeCostRefresh(false);
				toastText(response.data.data.message, 'success');

				setIsLoading(false);

				const _query: any = {
					openDrawer: 'true',
					activeKey: 'Configurations',
				};

				if (queryParams.get('payPeriodId')) {
					_query.payPeriodId = payPeriodId;
				}

				if (queryParams.get('isNewPayPeriod') === 'true') {
					_query.isNewPayPeriod = 'true';
				}

				setQueryParams(_query);
			}
		}
	};

	return (
		<>
			{employeeData && columns ? (
				<>
					<SearchAndFilter
						searchValue={searchValue}
						onChangeSearchValue={(value: any) => {
							setSearchValue(value);
							setApplySearch(true);
							onChangeSearchValue && onChangeSearchValue(value);
						}}
						// dateRangeDateHandler={(dateRange: any) =>
						// 	dateRangeDateHandler(dateRange)
						// }
						// payPeriodDateRange={payPeriodDateRange}
						// onSavePayPeriod={() => onSavePayPeriod()}
						onChangePayPeriodId={(id: any) => {
							setPayPeriodId(id);
							setQueryParams({
								payPeriodId: id,
							});
							setCurrentPage(1);
							setIsNewPayPeriod('false');
							localStorage.setItem('payPeriod', id);
							onChangePayPeriod && onChangePayPeriod(id);
							onChangeCostValue(false);
							setApplySearch(true);
						}}
						payPeriodId={payPeriodId}
						currentDatePayPeriodId={currentDatePayPeriodId}
						onSyncEmployee={() => {
							setPayPeriodId(null);
							localStorage.removeItem('payPeriod');
							onChangePayPeriod && onChangePayPeriod(null);
						}}
						closingDate={closingDate}
						handleInactive={(flag: any) => {
							handleInactive(flag);
							setApplySearch(true);
						}}
						includeInactive={includeInactive}
						showEmployeeWithAllocationConfig={showEmployeeWithAllocationConfig}
						isConfigurationPermission={isConfigurationPermission}
						onChangeShowEmployeeWithAllocationConfig={(flag) => {
							onChangeShowEmployeeWithAllocationConfig(flag);
							setApplySearch(true);
						}}
						handleShowPayPeriodCreationRefresh={
							handleShowPayPeriodCreationRefresh
						}
					/>

					<Table
						dataSource={
							totalCostRow ? [...employeeData, totalCostRow] : employeeData
						}
						scroll={{ y: 'calc(80vh - 310px)' }}
						pagination={false}
						// ref={tableRef}
						rowKey={(record: any) => {
							return record.employeeName;
						}}
						sticky
						className="employee-cost"
						onChange={tableChangeHandler}
						footer={() => {
							return (
								<p className="employee-cost-footer">
									Total No. of Employee: {count}
								</p>
							);
						}}
						rowClassName={(record: any) => {
							if (record.employeeName === 'Total') {
								return 'employee-cost-row';
							}
							return '';
						}}
						loading={isLoading}
					>
						{columns?.map((singleColumn: any) => {
							return (
								// isEmployeeCost &&
								// !singleColumn?.isHoursSetup && (
								<Column
									title={() => {
										return (
											// <Tooltip>
											<div className="employee-cost-column-title">
												<p>{singleColumn.svg}</p>
												<p>{singleColumn?.title}</p>
												{!singleColumn.isSectionLastColumn &&
													singleColumn.dataIndex !== 'totalLaborBurden' && (
														<Tooltip
															title={
																singleColumn.isCheckedField
																	? `Uncheck to convert ${singleColumn?.title} values to positive.`
																	: `Check to convert ${singleColumn?.title} values to negative.`
															}
														>
															<Checkbox
																checked={singleColumn.isCheckedField}
																onChange={(e) =>
																	handleCheckboxChange(
																		singleColumn,
																		e.target.checked
																	)
																}
															/>
														</Tooltip>
													)}
											</div>
											// </Tooltip>
										);
									}}
									className={`${singleColumn.className} ${
										singleColumn.isSectionLastColumn && 'last-column'
									} ${singleColumn.cellColor}`}
									showSorterTooltip={{ title: '' }}
									defaultSortOrder={singleColumn.defaultSortOrder}
									sorter={singleColumn.sorter}
									sortDirections={singleColumn.sortDirections}
									sortIcon={singleColumn.sortIcon}
									dataIndex={singleColumn?.dataIndex}
									key={singleColumn?.key}
									fixed={singleColumn?.fieldName == 'Employee Name' && 'left'}
									align={singleColumn?.isTotalField ? 'right' : 'left'}
									width={singleColumn?.width}
									render={(value: string, employeeData: any, index: any) => {
										const calculatorData = {
											title: singleColumn?.fieldName,
											employee: employeeData?.employeeName,
										};
										return singleColumn?.inputNumberField === 'Select' ? (
											<Select
												placeholder="Select Employee Type"
												size="large"
												style={{ width: '100%' }}
												onChange={(value: any) => {
													changeHandler(
														index,
														singleColumn?.dataIndex,
														value,
														'select',
														false
													);
												}}
												disabled={
													!isEditEmployeeCostPermission || !employeeData?.status
												}
												value={value}
												options={[
													{
														value: 'salaried_exempt',
														label: 'Salaried Exempt',
													},
													{
														value: 'salaried_non_exempt',
														label: 'Salaried Non Exempt',
													},
													{ value: 'hourly', label: 'Hourly' },
												]}
											/>
										) : singleColumn?.inputNumberField === 'Number' ? (
											<div className={styles['dynamic-table__status']}>
												{singleColumn?.isHours == true ? (
													<Input
														value={value}
														size="large"
														className={
															singleColumn.cellWidth === 130
																? 'width-130'
																: 'width-90'
														}
														defaultValue={value}
														disabled={
															!isEditEmployeeCostPermission ||
															!employeeData?.status
														}
														onChange={(event: any) => {
															changeHandler(
																index,
																singleColumn?.dataIndex,
																event?.target?.value,
																'hours',
																false
															);
														}}
													/>
												) : (
													<>
														{employeeData.employeeName !== 'Total' ? (
															<NumericFormat
																allowNegative={true}
																allowLeadingZeros={true}
																decimalScale={
																	configurationsData?.decimalToFixedAmount || 2
																}
																fixedDecimalScale={true}
																thousandSeparator=","
																customInput={Input}
																value={value}
																className={
																	singleColumn.cellWidth === 130
																		? 'width-130'
																		: 'width-120'
																}
																min={0}
																disabled={
																	!isEditEmployeeCostPermission ||
																	!employeeData?.status ||
																	!payPeriodId ||
																	isAccountClosed
																}
																size="large"
																prefix={'$'}
																onChange={() => {
																	onChangeCostValue(true);
																}}
																onValueChange={(values: any) => {
																	handleChangeCostValue(
																		index,
																		singleColumn?.dataIndex,
																		values?.floatValue
																	);
																}}
																onBlur={() => {
																	changeHandler(
																		index,
																		singleColumn?.dataIndex,
																		value,
																		'number',
																		false
																	);
																}}
															/>
														) : (
															<NumericFormat
																value={value}
																thousandSeparator=","
																decimalScale={
																	configurationsData?.decimalToFixedAmount || 2
																}
																fixedDecimalScale={true}
																displayType="text"
																prefix="$"
																renderText={(_value) => (
																	<h4
																		className={styles['dynamic-table-total']}
																		style={{
																			textAlign: 'right',
																		}}
																	>
																		{_value ?? 0}
																	</h4>
																)}
															/>
														)}
													</>
												)}
											</div>
										) : (
											<div>
												{!(singleColumn.dataIndex === 'employeeName') ? (
													<NumericFormat
														value={value}
														thousandSeparator=","
														decimalScale={
															configurationsData?.decimalToFixedAmount || 2
														}
														fixedDecimalScale={true}
														displayType="text"
														prefix="$"
														renderText={(_value) => (
															<h4
																className={styles['dynamic-table-total']}
																style={{
																	textAlign: 'right',
																}}
															>
																{_value ? _value : '$0.00'}
															</h4>
														)}
													/>
												) : employeeData.employeeName != 'Total' ? (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
														}}
													>
														<a
															className={styles['dynamic-table__employee']}
															href={`https://app${
																qboMode != 'prod' ? '.sandbox' : ''
															}.qbo.intuit.com/app/employees`}
															target="blank"
														>
															<span
																className={
																	styles['dynamic-table__employee-name']
																}
															>
																{value}
															</span>{' '}
															<QuickbooksEmployee />{' '}
															<span
																className={
																	styles[
																		'dynamic-table__employee-name-inactive'
																	]
																}
															>
																{!employeeData?.status && '(Inactive)'}
															</span>
															<br />
															<span style={{ color: 'black' }}>
																{employeeData?.hasEmployeeDirectAllocationConfig &&
																	'(Fixed %)'}
															</span>
														</a>
														<div
															style={{
																cursor: 'pointer',
															}}
															onClick={() => {
																setISConfigModalOpen(true);
																setSelectedEmployeeConfig({
																	id: employeeData.employeeId,
																	name: employeeData.employeeName,
																});
															}}
														>
															<Tooltip title="Set employee direct allocation">
																<PercentageOutlined />
															</Tooltip>
														</div>
													</div>
												) : (
													<>
														<span
															className={styles['dynamic-table__employee-name']}
														>
															{value}
														</span>
													</>
												)}
											</div>
										);
									}}
								/>
								// )
							);
						})}
					</Table>
				</>
			) : (
				<div
					style={{
						position: 'fixed',
						top: 'calc(48vh + 70px)',
						left: 'calc(48vw + 150px)',
					}}
				>
					<Loader />
				</div>
			)}
			{isMessageModalOpen && (
				<MessageModal
					isModalOpen={isMessageModalOpen}
					handleCancel={closeModal}
					message="You need to create pay period first before using employee cost"
				/>
			)}
			{isConfigModalOpen ? (
				<EmployeeConfigModal
					open={isConfigModalOpen}
					onCloseModal={() => setISConfigModalOpen(false)}
					selectedEmployee={selectedEmployeeConfig}
					onConfigured={() => {
						setISConfigModalOpen(false);
						getEmployeeCostData();
					}}
					isClassRequiredForJournal={
						configurationsData?.isClassRequiredForJournal
					}
					isCustomerRequiredForJournal={
						configurationsData?.isCustomerRequiredForJournal
					}
				/>
			) : null}
			{openEmployeeCostModal ? (
				<ImportEmployeeCostCsvModal
					open={openEmployeeCostModal}
					onClose={() => handleEmployeeModalClose()}
				/>
			) : null}
			{openValueUpdateModal && (
				<HeaderValueToggleModal
					isOpen={openValueUpdateModal}
					data={headerData}
					onCancel={handleCancel}
					onProceed={handleProceed}
					isLoading={isProceedLoading}
				/>
			)}
		</>
	);
};

export default TestTable;
