import { Button, ConfigProvider, Modal, Table, Upload } from 'antd';
import './index.scss';
import { CloseSvg } from 'utils/svgs';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { getApiCSV, postApi } from 'redux/apis';
import { toastText } from 'utils/utils';
import csvToJson from 'csvtojson';
import { useNavigate } from 'react-router-dom';

export const ImportEmployeeCostCsvModal = (props: any) => {
	const navigate = useNavigate();

	const [jsonResult, setJsonResult] = useState<any[]>([]);

	const [tableHeaders, setTableHeader] = useState<any>([]);

	const [loading, setLoading] = useState(false);

	const [loadingSaveEmployeeCost, setLoadingSaveEmployeeCost] = useState(false);

	const exportEmployeeCostFile = async () => {
		const payPeriodId = localStorage.getItem('payPeriod');
		const companyId = localStorage.getItem('companyId');
		const companyName = localStorage.getItem('companyName');

		try {
			setLoading(true);
			const response: any = await getApiCSV('/employee-cost/sample-export', {
				payPeriodId,
				companyId,
			});
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Employee_Cost_${companyName}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (error: any) {
			console.log(error);
			if (error?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			} else {
				toastText('Error while exporting employee cost csv', 'error');
			}
		}

		setLoading(false);
	};

	const handleFileUpload = async (info: any) => {
		if (info.file) {
			const reader = new FileReader();
			reader.onload = async (e: any) => {
				const csvData = e.target.result;

				const headers = csvData.split('\n')[0].split(',');

				const jsonData = await csvToJson({
					headers, // Don't use first row as headers
					includeColumns: /./, // Include all columns
				}).fromString(csvData);

				const _jsonData: any[] = [];

				jsonData.forEach((data) => {
					const obj: any = {};
					Object.keys(data).forEach((key) => {
						obj[key.trim()] = data[key];
					});
					_jsonData.push(obj);
				});

				const data = _jsonData[0];

				setTableHeader(
					Object.keys(data).map((e) => {
						return {
							title: e,
							dataIndex: e,
							key: e,
							width: 200,
						};
					})
				);

				setJsonResult(_jsonData);
			};
			reader.readAsText(info.file);
		}
	};

	const handleSaveEmployeeCost = async () => {
		setLoadingSaveEmployeeCost(true);
		try {
			const res = await postApi('/employee-cost/import', {
				payPeriodId: localStorage.getItem('payPeriod'),
				companyId: localStorage.getItem('companyId'),
				employeeCostData: jsonResult,
			});

			if (res.data.data.success) {
				toastText('Employee cost updated successfully', 'success');
				props.onClose && props.onClose();
			}
		} catch (error: any) {
			console.log(error);
			toastText('Error while updating employee cost', 'error');
		}
		setLoadingSaveEmployeeCost(false);
	};

	return (
		<Modal
			open={props.open}
			closable={false}
			footer={false}
			className="employee-cost-import-modal"
			width={1500}
		>
			<ConfigProvider
				theme={{
					components: {
						Button: {
							colorPrimaryHover: 'black',
							colorPrimary: 'black',
							borderRadius: 1000,
							controlHeight: 50,
							fontSize: 16,
							colorBorder: 'black',
						},
					},
				}}
			>
				<div className="employee-cost-import-modal__title">
					<div className="employee-cost-import-modal__title-label">
						Import Employee Cost
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							props.onClose && props.onClose();
						}}
					>
						<CloseSvg />
					</div>
				</div>
				<div className="employee-cost-import-modal__container">
					<p className="employee-cost-import-modal__container--notes">
						Note: Download the CSV file below, update the values, and then
						re-upload the updated CSV file to change the employee cost values
					</p>
					<div className="employee-cost-import-modal__container--buttons">
						<Button
							onClick={() => exportEmployeeCostFile()}
							loading={loading}
							type="primary"
							style={{ backgroundColor: '#344735' }}
						>
							<DownloadOutlined /> Export Employee Cost CSV
						</Button>
						<Upload
							accept="text/csv"
							onChange={(e) => {
								handleFileUpload(e);
							}}
							showUploadList={false}
							beforeUpload={() => false}
						>
							<Button
								style={{ marginLeft: '10px', width: '150px' }}
								icon={<UploadOutlined />}
							>
								Upload
							</Button>
						</Upload>
					</div>
					{jsonResult && jsonResult.length ? (
						<>
							<div className="employee-cost-import-modal__container--table">
								<Table
									scroll={{ y: '40vh' }}
									pagination={false}
									dataSource={jsonResult}
									columns={tableHeaders}
									rowKey={(data) => {
										return data['Employee'];
									}}
								/>
							</div>
							<div className="employee-cost-import-modal__container--buttons">
								<Button
									onClick={() => handleSaveEmployeeCost()}
									style={{ width: '150px' }}
									loading={loadingSaveEmployeeCost}
									type="primary"
								>
									{' '}
									Save
								</Button>
								<Button
									onClick={() => {
										setJsonResult([]);
										setTableHeader([]);
									}}
									style={{ marginLeft: '10px', width: '150px' }}
								>
									{' '}
									Cancel
								</Button>
							</div>
						</>
					) : null}
				</div>
			</ConfigProvider>
		</Modal>
	);
};
